<template>
    <div id="app">
        <router-view/>
    </div>

</template>


<script>
    import {mSeo} from '@/request/api'
    export default {
        created() {
            this.getSeo()
        },
        methods:{
            async getSeo(){
                       let {data}=await mSeo();
                       let i=data[0];
                document.querySelector('meta[name="keywords"]').setAttribute('content', i.seo_keyword);
                document.querySelector('meta[name="description"]').setAttribute('content', i.seo_desc);
                document.title = i.seo_title || to.meta.title;
            }
        }
    }

</script>
<style>

    .el-message{top:40vh!important;}
    .dialog-message {
        padding-bottom: 20px !important;
    }

    .dialog-message .el-message-box__header,.dialog .el-dialog__header {

        background: #eee;
        height:45px;
        display: flex;
        align-items: center;
        padding: 0 20px;


    }

    .dialog-message .el-message-box__headerbtn {
        top: 10px;


    }

    .dialog-message .el-message-box__headerbtn .el-message-box__close,  .dialog .el-dialog__headerbtn .el-dialog__close {
        font-size: 20px;
    }

    .dialog-message .el-message-box__content {
        padding: 30px 40px
    }

    .dialog-message .el-message-box__btns  {
        padding: 5px 20px 0;
        display: flex;
        justify-content: flex-end;
    }

    .dialog-message .el-message-box__btns .el-button:nth-child(2) {
        order: 0;
        margin-right: 10px;
    }
    .dialog-message .el-message-box__btns .el-button:first-child {
        order: 1;

    }
   .dialog-message .el-message-box__status.el-icon-warning {
       color: #FF6D0F;
   }
    .dialog .el-input__inner{width: 440px;}

    .v-modal {
        z-index: 2000!important;
    }
    .dialog .el-dialog__headerbtn {top:14px;}
    .el-input__inner,.el-button{border-radius: 0!important;}
    .el-form-item__label {font-size: 16px!important;}
    .el-message--success {background: #386FF0!important;}
    .el-message--success i,.el-message--success p{color: white!important;}
    .el-empty__description p{color: black!important;}
    .error-img,.image-slot {height: 100%;width: 100%;background: #f3f4f6;display: flex;justify-content: center;align-items: center;}
    .logo-img{width:220px;}
    .el-breadcrumb__inner{color:#999!important;}
    .el-breadcrumb__inner.is-link, .el-breadcrumb__inner a{font-weight: normal!important;}
</style>
