import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        shopNum: 0,
        role: '',    //用户角色 1普通用户 2企业经理 3员工
        userInfo: {},
        bottomInfo:{}
    },
    mutations: {
        getShopNum(state, num) {
            state.shopNum = num;
        },
        getRole(state, id) {
            state.role = id;
        },
        getBottomInfo(state,data){
            state.bottomInfo=data;
        },
        getUserInfo(  state, info ) {
            state.userInfo=info;
        }
    }
})

