import Vue from 'vue'
import App from './App.vue'
import router from './router';
import '@/assets/css/comon.scss'
import '@/assets/css/reset.css'
import '@/assets/css/iconfont.css'

import ElementUI from 'element-ui';
import '@/assets/css/element-variables.scss'
import {fomartDate,fixed,needLogin,formatRichText,back} from "@/request/utils";
import store from './store/index';

import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);
Vue.config.productionTip = false
Vue.prototype.$fomartDate=fomartDate;
Vue.prototype.$formatRichText=formatRichText;
Vue.prototype.$fixed=fixed;
Vue.prototype.$needLogin=needLogin;
Vue.prototype.$back=back;
Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

Vue.use(ElementUI);
new Vue({
  render: h => h(App), router,store
}).$mount('#app')
