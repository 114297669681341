function Covering(num) {
    return num >= 10 ? num : '0' + num
}

function fomartDate(date, n) {
    let ms = new Date(date * 1000);
    var y = ms.getFullYear()
    var m = ms.getMonth() + 1
    var d = ms.getDate()
    var H = ms.getHours()
    var M = ms.getMinutes()
    var S = ms.getSeconds()
    let date1 = y + '-' + Covering(m) + '-' + Covering(d) + ' ' + Covering(H) + ':' + Covering(M) + ':' + Covering(S);
    if (n) {
        return date1.substring(0, n)
    } else {
        return date1
    }

}

//格式化图片
function formatRichText(html) {
    let newContent = html.replace(/<img[^>]*>/gi, function (match, capture) {
        match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
        match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
        match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
        return match;
    });
    newContent = newContent.replace(/style="[^"]+"/gi, function (match, capture) {
        match = match.replace(/width:[^;]+;/gi, 'max-width:100%;').replace(/width:[^;]+;/gi,
            'max-width:100%;');
        return match;
    });
    newContent = newContent.replace(/<br[^>]*\/>/gi, '');
    newContent = newContent.replace(/\<img/gi,
        '<img style="width:100%;height:auto;object-fit:contain;padding:0;margin:0;display:flex;"');
    return newContent;
}

function fixed(val) {
    return Number(val).toFixed(2)
}

function needLogin(res) {
    if (!localStorage.getItem('ylsToken')) {
        this.$confirm('请先登录,再来继续操作', '提示', {
            customClass: 'dialog-message',
            type: 'warning'
        }).then(() => {
            this.$router.push({name: 'login'})
        })
    } else {
        res();
    }

}
function back(){
    window.history.go(-1)
}
// 验证码倒计时
const time = 60;
const domain = process.env.NODE_ENV == 'development' ? 'https://www.jxyls.cc' : 'https://www.jxyls.cc'
export {
    fomartDate, time, domain, formatRichText, fixed, needLogin,back
}
