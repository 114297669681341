import axios from 'axios'
import router from "../router";
import {mSeo} from './api'

router.beforeEach((to, from, next) => {
    console.log(123)
    let token = localStorage.getItem('ylsToken')
    mSeo().then((res) => {
        for (let i of res.data) {
            if (i.id == to.meta.id) {
                document.querySelector('meta[name="keywords"]').setAttribute('content', i.seo_keyword);
                document.querySelector('meta[name="description"]').setAttribute('content', i.seo_desc);
                document.title = i.seo_title || to.meta.title;
                return
            } else {
                document.querySelector('meta[name="keywords"]').setAttribute('content', '');
                document.querySelector('meta[name="description"]').setAttribute('content', '');
               if(to.meta.path!='/productDetail'){
                   document.title = to.meta.title;
               }
            }
        }
    })
    next()
})

//创建axios的一个实例
var instance = axios.create({
    baseURL: process.env.NODE_ENV == 'development' ? '/api' : 'https://www.jxyls.cc',//接口统一域名
    timeout: 50000                                                       //设置超时
})


//------------------- 一、请求拦截器 忽略
instance.interceptors.request.use(function (config) {
    config.headers['Content-Type'] = 'text/plain';

    return config;
}, function (error) {
    // 对请求错误做些什么
    //       console.log(error)
    return Promise.reject(error);
});

//----------------- 二、响应拦截器 忽略
instance.interceptors.response.use(function (response) {
        // console.log(response.data)
    return response.data;
}, function (error) {
  if(error=='Error: Request failed with status code 401'){
      localStorage.removeItem('ylsToken')
  }
  console.log('error'+error);
    return Promise.reject(error);
});

/**
 * 使用es6的export default导出了一个函数，导出的函数代替axios去帮我们请求数据，
 * 函数的参数及返回值如下：
 * @param {String} method  请求的方法：get、post、delete、put
 * @param {String} url     请求的url:
 * @param {Object} data    请求的参数
 * @returns {Promise}     返回一个promise对象，其实就相当于axios请求数据的返回值
 */
export default function (method, url, data = null) {
    let obj = Object.assign({token: localStorage.getItem('ylsToken')}, data);
    if (method == 'post') {
        return instance.post(url,{params: obj})
    } else if (method == 'get') {
        return instance.get(url, {params: obj})
    } else if (method == 'delete') {
        return instance.delete(url, {params: obj})
    } else if (method == 'put') {
        return instance.put(url, obj)
    } else {
        console.error('未知的method' + method)
        return false
    }
}
