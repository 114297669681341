import Vue from 'vue'
import Router from 'vue-router'

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)
const routes = [
    {
        path: '',
        name: '',
        component: () => import('@/view/layout2/index'),
        children: [

            {
                path: '/allCate',
                name: 'allCate',

                component: () => import('@/view/home/allCate'),
                meta: {title: '全部商品分类', path: '/allCate', icon: 'icon-fenlei1'}
            },
            {
                path: '/',
                name: '/',
                component: () => import('@/view/home/index'),
                meta: {title: '首页', path: '/',id:1}
            },
            {
                path: '/aboutUs',
                name: 'aboutUs',
                component: () => import('@/view/home/aboutUs'),
                meta: {title: '关于我们', path: '/aboutUs',id:2}
            },

            {
                path: '/brand',
                name: 'brand',
                component: () => import('@/view/home/brand'),
                meta: {title: '品牌专区', path: '/brand',id:3}
            }, {
                path: '/IndustryConsultancy',
                name: 'IndustryConsultancy',
                component: () => import('@/view/home/IndustryConsultancy'),
                meta: {title: '行业资讯', path: '/IndustryConsultancy',id:4}
            },
            {
                path: '/biddingInformation',
                name: 'biddingInformation',
                component: () => import('@/view/home/biddingInformation'),
                meta: {title: '集采及招投标信息', path: '/biddingInformation',id:5}
            },
            {
                path: '/medical',
                name: 'medical',
                component: () => import('@/view/home/medical'),
                meta: {title: '医保及挂网信息', path: '/medical',id:6}
            },
            {
                path: '/findArticle',
                name: 'findArticle',
                component: () => import('@/view/searchModule/articleList'),
                meta: {title: '搜索文章', path: '/findArticle',}
            }, {
                path: '/findGood',
                name: 'findGood',
                component: () => import('@/view/searchModule/findGood'),
                meta: {title: '搜索商品', path: '/findGood',}
            },
            {
                path: '/licenseOfmedical',
                name: 'licenseOfmedical',
                component: () => import('@/view/lience/licenseOfmedical'),
                meta: {title: '医疗许可证', path: '/licenseOfmedical'}
            },

            {
                path: '/serviceCertificate',
                name: 'serviceCertificate',
                component: () => import('@/view/lience/serviceCertificate'),
                meta: {title: '服务证书', path: '/serviceCertificate'}
            }, {
                path: '/businessLicense',
                name: 'businessLicense',
                component: () => import('@/view/lience/businessLicense'),
                meta: {title: '营业执照', path: '/businessLicense'}
            },
            {
                path: '/articleType',
                name: 'articleType',
                component: () => import('@/view/industryModule/articleType'),
                meta: {title: '文章类型', path: '/articleType',}
            }, {
                path: '/articleDetail',
                name: 'articleDetail',
                component: () => import('@/view/industryModule/articleDetail'),
                meta: {title: '文章详情', path: '/articleDetail',}
            }, {
                path: '/brandsGood',
                name: 'brandsGood',
                component: () => import('@/view/searchModule/brandGood'),
                meta: {title: '品牌商品', path: '/brandsGood',}
            },
            {
                path: '/productDetail',
                name: 'productDetail',
                component: () => import('@/view/productModule/productDetail'),
                meta: {title: '商品详情', path: '/productDetail',}
            },
            {
                path: '/shoppingCart',
                name: 'shoppingCart',
                component: () => import('@/view/shoppingCartModule/shoppingCart'),
                meta: {title: '购物车', path: '/shoppingCart',}
            },
            {
                path: '/settlement',
                name: 'settlement',
                component: () => import('@/view/shoppingCartModule/settlement'),
                meta: {title: '结算', path: '/settlement', wayNav: true, active: 1}
            },
            {
                path: '/pay',
                name: 'pay',
                component: () => import('@/view/shoppingCartModule/pay'),
                meta: {title: '结算', path: '/pay', wayNav: true, active: 2}
            }, {
                path: '/pay2',
                name: 'pay2',
                component: () => import('@/view/shoppingCartModule/pay2'),
                meta: {title: '结算', path: '/pay', wayNav: true, active: 2}
            }, {
                path: '/pay3',
                name: 'pay3',
                component: () => import('@/view/shoppingCartModule/pay3'),
                meta: {title: '结算', path: '/pay', wayNav: true, active: 2}
            },{
                path: '/pay4',
                name: 'pay4',
                component: () => import('@/view/shoppingCartModule/pay4'),
                meta: {title: '结算', path: '/pay', wayNav: true, active: 2}
            },
            {
                path: '/forgetPay',
                name: 'forgetPay',
                component: () => import('@/view/shoppingCartModule/forgetPay'),
                meta: {title: '修改支付密码', path: '/pay', wayNav: true, active: -1}
            }, {
                path: '/orderDetail',
                name: 'orderDetail',
                component: () => import('@/view/personCenterModule/order/orderDetail'),
                meta: {title: '订单详情', path: '/myOrder', icon: 'icon-dingdan', breadNumber: 1},
            },
            {
                path: '/afterDetail',
                name: 'afterDetail',
                component: () => import('@/view/personCenterModule/afterSaleMange/afterDetail'),
                meta: {title: '售后详情', path: '/afterDetail', }
            },
            {
                path: '/supplierInfo',
                name: 'supplierInfo',
                component: () => import('@/view/supplier/home'),
                meta: {title: '供应商详情',}
            },
            {
                path: '/personData',
                name: 'personData',
                component: () => import('@/view/personCenterModule/index'),
                meta: {title: '个人中心', path: '/personData'},
                children: [{
                    path: '/personData',
                    name: 'personData',
                    component: () => import('@/view/personCenterModule/personData/index'),
                    meta: {title: '基本资料', path: '/personData1', icon: 'icon-changyongicon-', breadNumber: 1},
                },
                    {
                        path: '/myOrder',
                        name: 'myOrder',
                        component: () => import('@/view/personCenterModule/order/index'),
                        meta: {title: '我的订单', path: '/myOrder', icon: 'icon-dingdan', breadNumber: 1},
                    },
                    {
                        path: '/applyAfter',
                        name: 'applyAfter',
                        component: () => import('@/view/personCenterModule/order/applyAfter'),
                        meta: {title: '申请售后', path: '/myOrder', icon: 'icon-dingdan', breadNumber: 1},
                    },
                    {
                        path: '/messageCenter',
                        name: 'messageCenter',
                        component: () => import('@/view/personCenterModule/personData/messageCenter'),
                        meta: {title: '消息中心', path: '/personData', icon: 'icon-changyongicon-', breadNumber: 2},
                    },
                    {
                        path: '/messageDetail',
                        name: 'messageDetail',
                        component: () => import('@/view/personCenterModule/personData/messageDetail'),
                        meta: {title: '消息详情', path: '/personData', icon: 'icon-changyongicon-', breadNumber: 2},
                    },
                    {
                        path: '/enterpriseCert',
                        name: 'enterpriseCert',
                        component: () => import('@/view/personCenterModule/enterprise/enterpriseCert'),
                        meta: {title: '企业认证', path: '/enterpriseCert', icon: 'icon-gongsi', breadNumber: 1},
                    },
                    {
                        path: '/waiting',
                        name: 'waiting',
                        component: () => import('@/view/personCenterModule/enterprise/waiting'),
                        meta: {title: '企业认证', path: '/enterpriseCert', icon: 'icon-changyongicon-', breadNumber: 1},
                    },
                    {
                        path: '/certFail',
                        name: 'certFail',
                        component: () => import('@/view/personCenterModule/enterprise/certFail'),
                        meta: {title: '企业认证', path: '/enterpriseCert', breadNumber: 1},
                    }, {
                        path: '/updateCert',
                        name: 'updateEnter',
                        component: () => import('@/view/personCenterModule/enterprise/updateEnter'),
                        meta: {title: '企业认证', path: '/enterpriseCert', breadNumber: 1},
                    },
                    {
                        path: '/accountMange',
                        name: 'accountMange',
                        component: () => import('@/view/personCenterModule/accountMange/index'),
                        meta: {title: '账期管理', path: '/accountMange', breadNumber: 1, icon: 'icon-zhangdan'},
                    },
                    {
                        path: '/contract',
                        name: 'contract',
                        component: () => import('@/view/personCenterModule/contractMange/index'),
                        meta: {title: '合同管理', path: '/contract', icon: 'icon-hetong', breadNumber: 1},
                    },
                    {
                        path: '/cooperativeGoods',
                        name: 'cooperativeGoods',
                        component: () => import('@/view/personCenterModule/cooperativeGoods/index'),
                        meta: {title: '合作商品', path: '/cooperativeGoods', icon: 'icon-shangpin', breadNumber: 1},
                    },
                    {
                        path: '/auditCenter',
                        name: 'auditCenter',
                        component: () => import('@/view/personCenterModule/auditCenter/index'),
                        meta: {title: '审核中心', path: '/auditCenter', icon: "icon-yinzhanggaizhang", breadNumber: 1},
                    },
                    {
                        path: '/qualifications',
                        name: 'qualifications',
                        component: () => import('@/view/personCenterModule/qualifications/index'),
                        meta: {title: '资质管理', path: '/qualifications', icon: 'el-icon-document-copy', breadNumber: 1},
                    },
                    {
                        path: '/addressMange',
                        name: 'addressMange',
                        component: () => import('@/view/personCenterModule/addressMange/index'),
                        meta: {title: '地址管理', path: '/addressMange', icon: 'icon-dingwei', breadNumber: 1},
                    },
                    {
                        path: '/collectGood',
                        name: 'collectGood',
                        component: () => import('@/view/personCenterModule/collect/index'),
                        meta: {title: '我的收藏', icon: 'icon-shoucang', breadNumber: 1, path: '/collectGood'},

                    },
                    {
                        path: '/invoice',
                        name: 'invoice',
                        component: () => import('@/view/personCenterModule/invoice/index'),
                        meta: {title: '发票信息', path: '/invoice', breadNumber: 1, icon: 'icon-fapiao'},
                    },

                    {
                        path: '/afterSale',
                        name: 'afterSale',
                        component: () => import('@/view/personCenterModule/afterSaleMange/index'),
                        meta: {title: '售后管理', path: '/afterSale', icon: 'icon-shouhou', breadNumber: 1},
                    },

                    {
                        path: '/evaluate',
                        name: 'evaluate',
                        component: () => import('@/view/personCenterModule/order/evaluate'),
                        meta: {title: '评价', path: '/myOrder', breadNumber: 2},
                    },



                    {
                        path: '/changeRecord',
                        name: 'changeRecord',
                        component: () => import('@/view/personCenterModule/qualifications/record'),
                        meta: {title: '变更记录', path: '/qualifications', breadNumber: 2},
                    },
                    {
                        path: '/contractDetail',
                        name: 'contractDetail',
                        component: () => import('@/view/personCenterModule/contractMange/contractDetail'),
                        meta: {title: '合同详情', path: '/contract', breadNumber: 2},
                    },
                    {
                        path: '/setting',
                        name: 'setting',
                        component: () => import('@/view/personCenterModule/modifyPwd/index'),
                        meta: {title: '设置', path: '/setting', icon: 'icon-shezhi', breadNumber: 1},
                    },
                    {
                        path: '/salesman',
                        name: 'salesman',
                        component: () => import('@/view/personCenterModule/salesman/index'),
                        meta: {title: '我是业务员', path: '/salesman', icon: "icon-fenfacuishouyewuyuan", },
                        children: [

                            {
                                path: '/customerOrder',
                                name: 'customOrder',
                                component: () => import('@/view/personCenterModule/salesman/customOrder'),
                                meta: {title: '客户订单', path: '/customerOrder', breadNumber:1}
                            },
                            {
                                path: '/myCustomer',
                                name: 'myCustomer',
                                component: () => import('@/view/personCenterModule/salesman/myCustomer'),
                                meta: {title: '我的客户', path: '/myCustomer', breadNumber: 1}
                            },
                            {
                                path: '/extension',
                                name: 'extension',
                                component: () => import('@/view/personCenterModule/salesman/extension'),
                                meta: {title: '我要推广', path: '/extension', breadNumber: 1}
                            },
                        ]
                    }, {
                        path: '/explain',
                        name: 'explain',
                        component: () => import('@/view/personCenterModule/explain'),
                        meta: {title: '功能说明', path: '/explain',icon: 'icon-shuben-shuoming', breadNumber: 1}
                    },
                ]
            },
            {
                path: '/helpCenter',
                name: 'helpCenter',
                component: () => import('@/view/helpCenterModule/helpCenter'),
                meta: {title: '帮助中心', path: '/helpCenter'}
            },
            {
                path: '/disclaimers',
                name: 'disclaimers',
                component: () => import('@/view/agreenment/disclaimers'),
                meta: {title: '免责声明', path: '/disclaimers'}
            }, {
                path: '/policy',
                name: 'policy',
                component: () => import('@/view/agreenment/policy'),
                meta: {title: '隐私政策', path: '/policy'}
            },

        ]
    },
    {
        path: '/login',
        name: 'layout',
        component: () => import('@/view/layout/index'),
        children: [
            {
                path: '/login',
                name: 'login',
                component: () => import('@/view/account/login'),
                meta: {title: '登录'}
            },
            {
                path: '/pclogin',
                name: 'pclogin',
                component: () => import('@/view/account/pclogin'),
                meta: {title: '登录'}
            },
            {
                path: '/bind',
                name: 'bind',
                component: () => import('@/view/account/bind'),
                meta: {title: '绑定'}
            },
            {
                path: '/register',
                name: 'register',
                component: () => import('@/view/account/register'),
                meta: {title: '注册'}
            },
            {
                path: '/forget',
                name: 'forget',
                component: () => import('@/view/account/forget'),
                meta: {title: '找回密码'}
            }
        ]


    },
    {
        path: '/articleDetailWap',
        name: 'articleDetailWap',
        component: () => import('@/view/industryModule/articleDetailWap'),
        meta: {title: '文章详情', path: '/articleDetailWap',}
    },

    {
        path:'*',
        redirect:'/'
    }

]


export default new Router({
    mode: 'hash', // require service support
    routes
})
