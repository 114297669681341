import request from './http';

// method, url, data = null
//注册
export function uRegister(obj) {
    return request('get', '/api.php/user/register', obj)
}

// 忘记密码
export function uWjprwd(obj) {
    return request('get', '/api.php/User/wjprwd', obj)
}

//登录
export function Login(obj) {
    return request('get', '/api.php/user/login', obj)
}

//获取验证码
export function mSend_sms(obj) {
    return request('get', '/api.php/main/send_sms', obj)
}

//轮播图
export function mLunbotu(obj) {
    return request('get', '/api.php/main/pclunbotu', obj)
}

//关于我们
export function mGuanyu(obj) {
    return request('get', '/api.php/main/guanyu', obj)
}

// 新品推荐
export function mNew(obj) {
    return request('get', '/api.php/main/new', obj)
}

//商品推荐
export function mRecommend(obj) {
    return request('get', '/api.php/main/recommend', obj)
}

//备案号
export function mXukez(obj) {
    return request('get', '/api.php/main/xukez', obj)
}

// 商品品牌
export function gTypesa(obj) {
    return request('get', '/api.php/goods/typesa', obj)
}

// 商品分类
export function gChild_cate(obj) {
    return request('get', '/api.php/goods/child_cate', obj)
}

// 商品分类
export function gCate_goods(obj) {
    return request('get', '/api.php/goods/cate_goods', obj)
}

// 行业资讯栏目
export function mXingwens(obj) {
    return request('get', '/api.php/main/xingwens', obj)
}// 公司新闻
export function mXingwensa(obj) {
    return request('get', '/api.php/main/xingwensa', obj)
}

//商品详情
export function gDetail(obj) {
    return request('get', '/api.php/goods/detail', obj)
}

//收藏商品
export function cAdd_collection(obj) {
    return request('get', '/api.php/Collection/add_collection', obj)
}

//加入购物车
export function cAdd_cart(obj) {
    return request('get', '/api.php/cart/add_cart', obj)
}

//购物车数量
export function cCart_count(obj) {
    return request('get', '/api.php/cart/cart_count', obj)
}

//购物车列表
export function cIndex(obj) {
    return request('get', '/api.php/cart/index', obj)
}

//删除购物车
export function cDel_cart(obj) {
    return request('get', '/api.php/cart/del_cart', obj)
}

//更新购物车
export function cUpdate_cart_num(obj) {
    return request('get', '/api.php/cart/update_cart_num', obj)
}//获取地址
export function oIndex_address(obj) {
    return request('get', '/api.php/order/index_address', obj)
}//添加地址
export function oShipping_address(obj) {
    return request('get', '/api.php/order/shipping_address', obj)
}

//删除地址
export function oDel_address(obj) {
    return request('get', '/api.php/order/del_address', obj)
}//修改地址
export function oUpdate_address(obj) {
    return request('get', '/api.php/order/update_address', obj)
}

//获取地区
export function mRegion(obj) {
    return request('get', '/api.php/main/region', obj)
}

//获取自提点
export function oAddresspuick(obj) {
    return request('get', '/api.php/order/addresspuick', obj)
}//查找文章
export function mWenzselect(obj) {
    return request('get', '/api.php/main/wenzselect', obj)
}

//帮助中心
export function mBangzhu(obj) {
    return request('get', '/api.php/main/bangzhu', obj)
}//反馈类型
export function mFk_type(obj) {
    return request('get', 'api.php/main/fk_type', obj)
}//提交反馈
export function uFankui(obj) {
    return request('get', 'api.php/user/fankui', obj)
}//查找商品
export function gGoods_list(obj) {
    return request('get', '/api.php/goods/goods_list', obj)
}

//查找商品
export function mFist_cate_goods(obj) {
    return request('get', '/api.php/main/fist_cate_goods', obj)
}//获取订单标题
export function mMyorder(obj) {
    return request('get', '/api.php/main/myorder', obj)
}

//热门搜索
export function mHot_search(obj) {
    return request('get', '/api.php/main/hot_search', obj)
}

//修改登录密码
export function uPasswords(obj) {
    return request('get', '/api.php/user/passwords', obj)
}

//判断用户角色 1普通用户 2企业经理 3员工
export function oJingli(obj) {
    return request('get', '/api.php/order/jingli', obj)
}

//品牌商品
export function gBrand(obj) {
    return request('get', '/api.php/goods/brand', obj)
}

//点击排行
export function mPaihang(obj) {
    return request('get', '/api.php/main/paihang', obj)
}

//文章详情
export function mXingwensas(obj) {
    return request('get', '/api.php/main/xingwensas', obj)
}

//申请企业
export function eAdd_Enterprise(obj) {
    return request('get', '/api.php/Enterprise/add_Enterprise', obj)
}

//申请企业信息
export function eIndexs_enterprise(obj) {
    return request('get', '/api.php/Enterprise/indexs_enterprise', obj)
}//修改企业信息
export function eEnterprise_xiuga(obj) {
    return request('get', '/api.php/Enterprise/Enterprise_xiuga', obj)
}//是否设置支付密码
export function uZhifumima(obj) {
    return request('get', '/api.php/user/zhifumima', obj)
}//账期管理
export function eEnterprise(obj) {
    return request('get', '/api.php/Enterprise/enterprise', obj)
}//集采及招标信息
export function mBidding(obj) {
    return request('get', '/api.php/main/bidding', obj)
}

//医保及挂网信息
export function mMedical(obj) {
    return request('get', '/api.php/main/medical', obj)
}//seo
export function mSeo(obj) {
    return request('get', '/api.php/main/seo', obj)
}//申请额度
export function adds_enterprise_money(obj) {
    return request('get', '/api.php/Enterprise/adds_enterprise_money', obj)
}//新闻头条
export function xms(obj) {
    return request('get', '/api.php/main/xws', obj)
}
//收藏文章
export function fav(obj) {
    return request('get', '/api.php/user/fav', obj)
}
//点赞文章
export function zan(obj) {
    return request('get', '/api.php/user/zan', obj)
}//修改默认地址
export function address_uppdate(obj) {
    return request('get', '/api.php/order/address_uppdate', obj)
}
//邮费
export function youfei(obj) {
    return request('get', '/api.php/order/youfei', obj)
}//下单
export function add_order(obj) {
    return request('get', '/api.php/order/add_order', obj)
}
//订单详情
export function order_detail(obj) {
    return request('get', '/api.php/order/order_detail', obj)
}
//微信支付
export function pay(obj) {
    return request('get', '/api.php/order/pay', obj)
}//设置支付密码
export function shezhizf(obj) {
    return request('get', '/api.php/order/shezhizf', obj)
}//企业信息
export function timeas(obj) {
    return request('get', '/api.php/Order/timeas', obj)
}//验证支付密码
export function zf(obj) {
    return request('get', '/api.php/order/zf', obj)
}//下单后验证
export function if_enterprise(obj) {
    return request('get', '/api.php/order/if_enterprise', obj)
}//下单后验证
export function update_zf(obj) {
    return request('get', '/api.php/order/update_zf', obj)
}
//忘记支付密码
export function wangji_zf(obj) {
    return request('get', '/api.php/order/wangji_zf', obj)
}
//订单列表
export function oIndex(obj) {
    return request('get', '/api.php/order/index', obj)
}//个人信息
export function uUser_info(obj) {
    return request('get', '/api.php/user/user_info', obj)
}//查看物流
export function oIndexwuliu(obj) {
    return request('get', '/api.php/order/indexwuliu', obj)
}
//确认收货
export function oOrder_receipt(obj) {
    return request('get', '/api.php/order/order_receipt', obj)
}//评价商品
export function eEvaluation_add(obj) {
    return request('get', '/api.php/Evaluation/evaluation_add', obj)
}
//申请退款
export function oRefund(obj) {
    return request('get', '/api.php/order/refund', obj)
}//取消订单
export function oCancel(obj) {
    return request('get', '/api.php/order/order_cancel', obj)
}//删除订单
export function oDel_order(obj) {
    return request('get', '/api.php/order/del_order', obj)
}
//发票信息
export function oFapia(obj) {
    return request('get', '/api.php/order/fapiao', obj)
}//企业信息
export function eIndex_enterprise(obj) {
    return request('get', '/api.php/Enterprise/index_enterprise', obj)
}
//修改企业企业
export function eUpdate_enterprise(obj) {
    return request('get', '/api.php/Enterprise/update_enterprise', obj)
}
//收藏的商品
export function cIndex_collection(obj) {
    return request('get', '/api.php/Collection/index_collection', obj)
}
//取消收藏的商品
export function cDel_collection(obj) {
    return request('get', '/api.php/Collection/del_collection', obj)
}
//收藏的文章
export function mNews_fav_list(obj) {
    return request('get', '/api.php/main/news_fav_list', obj)
}
//企业变更记录
export function eEnterprise_log(obj) {
    return request('get', '/api.php/Enterprise/enterprise_log', obj)
}//合作商品
export function gCooperative_goodsa(obj) {
    return request('get', '/api.php/Goods/cooperative_goodsa', obj)
}
//合同管理
export function eHetong(obj) {
    return request('get', '/api.php/Enterprise/hetong', obj)
}
//合同详情
export function eHetongcontent(obj) {
    return request('get', '/api.php/Enterprise/hetongcontent', obj)
}
//售后订单
export function oRefund_list(obj) {
    return request('get', '/api.php/order/refund_list', obj)
}
//获取业务员推广码
export function yIndex(obj) {
    return request('get', '/api.php/yewuyuan/index', obj)
}//我的客户
export function yMyteam(obj) {
    return request('get', '/api.php/yewuyuan/myteam', obj)
}
//业务员客户订单
export function yMyoeder(obj) {
    return request('get', '/api.php/yewuyuan/myoeder', obj)
}//审核中心
export function eEnterprise_shenhe(obj) {
    return request('get', '/api.php/Enterprise/enterprise_shenhe', obj)
}
//发布评论
export function mAdd_comment(obj) {
    return request('get', '/api.php/main/add_comment', obj)
}//评论列表
export function mComment_list(obj) {
    return request('get', '/api.php/main/comment_list', obj)
}
//增加文章浏览量
export function mWnzhang(obj) {
    return request('get', '/api.php/Main/wenzhang', obj)
}
//订单数量
export function oIndex_count(obj) {
    return request('get', '/api.php/order/index_count', obj)
}
//评论排行
export function mComment_paihang(obj) {
    return request('get', '/api.php/main/comment_paihang', obj)
}//经理审核订单
export function eJinli_shenehe(obj) {
    return request('get', '/api.php/Enterprise/jinli_shenehe', obj)
}
//退款订单详情
export function oRefund_detail(obj) {
    return request('get', '/api.php/order/refund_detail', obj)
}//取消退款
export function oCancel_refund(obj) {
    return request('get', '/api.php/order/cancel_refund', obj)
}//订单发票
export function oTax_param(obj) {
    return request('get', '/api.php/order/tax_param', obj)
}
//支付宝支付
export function oTo_alipay(obj) {
    return request('get', '/api.php/order/to_alipay', obj)
}//修改个人信息
export function uProfile(obj) {
    return request('get', '/api.php/user/profile', obj)
}//电话
export function mTel(obj) {
    return request('get', '/api.php/main/tel', obj)
}//系统消息
export function mIndex(obj) {
    return request('get', '/api.php/msg/index', obj)
}//系统消息详情
export function mDetail(obj) {
    return request('get', '/api.php/msg/detail', obj)
}//审核消息
export function mShenhe(obj) {
    return request('get', '/api.php/msg/shenhe', obj)
}
//访问量
export function gFangwenliang(obj) {
    return request('get', '/api.php/goods/fangwenliang', obj)
}
//访客量
export function gFangkeliang(obj) {
    return request('get', '/api.php/goods/fangkeliang', obj)
}
//判断有没有企业经理
export function mHas_manager(obj) {
    return request('get', '/api.php/main/has_manager', obj)
}
//判断有没有企业经理
export function mNewslunbotu(obj) {
    return request('get', '/api.php/main/newslunbotu', obj)
}//置顶文章
export function mTop_news(obj) {
    return request('get', '/api.php/main/top_news', obj)
}
// 微信登录
export function pc_wx_login(obj) {
    return request('get', '/api.php/user/pc_wx_login', obj)
}// 绑定微信
export function bind_user(obj) {
    return request('get', '/api.php/user/bind_user', obj)
}
// 获取供应商信息
export function get_sup_by_brand(obj) {
    return request('get', '/api.php/main/get_sup_by_brand', obj)
}
//功能说明
export function func_desc(obj) {
    return request('get', '/api.php/main/func_desc', obj)
}
// 验证参数
export function build_verify(obj) {
    return request('get', '/api.php/main/build_verify', obj)
}
//友情链接
export function flinks(obj) {
    return request('get', '/api.php/main/flinks', obj)
}
//隐私政策
export function bottom_info(obj) {
    return request('get', '/api.php/main/bottom_info', obj)
}//站内导航
export function map_nav(obj) {
    return request('get', '/api.php/main/map_nav', obj)
}





